import React, { useContext, useEffect, useState } from 'react';
import BlurryBackground from 'components/Modals/BlurryBackground';
import TournamentContext from 'context/TournamentContext';
import useWinners from 'hooks/useWinners';
import useFetch from 'hooks/useFetch';
import {
  announceWinnersButtonText,
  chooseWinnersHeadingText,
  confirmWinnersButtonText,
} from 'constants/text/dashboard';
import { PLAYERS_ANSWERS_URL, WINNERS_URL } from 'constants/apiRoutes';
import PlayerOption from 'components/PlayerOption/PlayerOption';
import useTimer from 'hooks/useTimer';
import { Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import convertSnakeToCamelCase from 'utilis/snakeToCamelCase';
import useIsAuto from 'hooks/useIsAuto';
import errorToast from 'components/toasts/errorToast';

function ChosePlayersModal({ tiebrake = false, withTimer = true }) {
  const { tournament } = useContext(TournamentContext);
  const { players } = tournament;
  const {
    winners, rightAnswer, updateRightAnswer, handleCheckboxChange,
  } = useWinners();

  const isAuto = useIsAuto({});
  const [showModal, setShowModal] = useState(false);
  const [allowToOpenAnswers, setAllowToOpenAnswers] = useState(withTimer === false);
  const [emptyAnswerError, setEmptyAnswerError] = useState(null);
  const [playersWithAnswers, setPlayersWithAnswers] = useState([]);

  const { time } = useTimer({
    // take the seconds left for first player, they are all the same for everybody
    // add extra time to avoid race conditions
    initialTime: players[0].secondsLeft + 3,
    onTimeOut: () => {
      setAllowToOpenAnswers(true);
    },
  });

  const {
    fetchData,
    isLoading,
  } = useFetch(`${WINNERS_URL}/${tournament.uniqueId}`, {
    method: 'PATCH',
    fetchOnMount: false,
    onError: (message) => {
      errorToast(message);
    },

  });

  const {
    fetchData: triggerTiebreak,
    isLoading: isTiebreakRequestLoading,
  } = useFetch(`api/tiebreak/${tournament.uniqueId}`, {
    fetchOnMount: false,
    method: 'PATCH',
  });

  const {
    fetchData: fetchPlayerAnswers,
    isLoading: areAnswersLoading,
  } = useFetch(`${PLAYERS_ANSWERS_URL}/${tournament.uniqueId}`, {
    fetchOnMount: false,
    method: 'GET',
    onSuccess: (data) => {
      setPlayersWithAnswers(convertSnakeToCamelCase(data));
    },
  });

  const openModal = () => {
    setShowModal(true);
  };

  const submitRightAnswers = () => {
    if (winners.length === 0 && rightAnswer === '') {
      setEmptyAnswerError('Трябва да зададете верен отговор');
      return;
    }
    setEmptyAnswerError(null);
    setShowModal(false);
    fetchData({ winners, right_answer: rightAnswer });
  };

  const chooseTiebrake = () => {
    triggerTiebreak({ right_answer: rightAnswer });
  };

  useEffect(() => {
    if (allowToOpenAnswers) {
      fetchPlayerAnswers();
    }
  }, [allowToOpenAnswers]);

  return (
    <>
      {showModal === false
      && (
      <LoadingButton
        type="button"
        onClick={openModal}
        loading={isLoading || areAnswersLoading}
        id="show_chose_player_modal_button"
        fullWidth
        variant="contained"
        disabled={!allowToOpenAnswers}
      >
        {!allowToOpenAnswers && `Остават ${Math.floor(time)} секунди`}
        {(allowToOpenAnswers) && announceWinnersButtonText}
      </LoadingButton>
      )}
      {showModal === true ? (
        <BlurryBackground>
          <form className="right_answer_modal" data-testid="right_answer_modal">
            {
              isAuto
            && (
            <h2 style={{ textAlign: 'center' }}>
              Верен отговор:
              {' '}
              {localStorage.getItem('correct_answer')}
            </h2>
            )
            }

            <h3>{chooseWinnersHeadingText}</h3>
            {playersWithAnswers?.data && playersWithAnswers.data.map((player, index) => {
              if (player.inHand === true || player.inHand === 1) {
                return (
                  <PlayerOption
                    player={player}
                    index={index}
                    handleCheckboxChange={handleCheckboxChange}
                    key={player.id}
                  />
                );
              }
              return null;
            })}
            <div className="right_answer_input_holder">
              <label htmlFor="right_answer">Въведи верният отговор</label>
              <input onChange={updateRightAnswer} id="right_answer" type="text" />
              {emptyAnswerError
                && <p style={{ color: 'red', textAlign: 'center', marginBottom: '1em' }} className="error">{emptyAnswerError}</p>}
            </div>

            <Stack spacing={2}>
              <Button
                disabled={isTiebreakRequestLoading || winners.length === 0}
                fullWidth
                variant="contained"
                onClick={submitRightAnswers}
                type="button"
                id="submit_chose_player_modal"
              >
                {confirmWinnersButtonText}
              </Button>
              {
                !tiebrake
              && (
              <LoadingButton
                loading={isTiebreakRequestLoading}
                fullWidth
                variant="contained"
                type="button"
                onClick={chooseTiebrake}
              >
                Няма верен отговор
              </LoadingButton>
              )
              }
            </Stack>
          </form>
        </BlurryBackground>
      ) : null}
    </>
  );
}

export default ChosePlayersModal;
